import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { ApolloProvider } from "react-apollo"
import { BrowserRouter as Router } from "react-router-dom"
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import client from "./utils/apolloClient"
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
