import React from 'react'
import { useParams } from "react-router"
import Query from '../components/Query'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'

import ARTICLE_QUERY from '../queries/article'

const Article = () => {
    let { id } = useParams()
    return (
        <Query query={ARTICLE_QUERY} id={id}>
            {({ data: { article } }) => {
              const imageUrl =
                article.image
                  ? article.image.url
                  : ''
                return (
                  <article className="uk-article">
                    <div
                      className="uk-height-large uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
                      data-src={imageUrl}
                      data-srcset={imageUrl}
                      data-uk-img
                    ></div>
                    <div className="uk-section">
                      <div className="uk-container uk-container-small">
                        <h1 className="uk-article-title">{article.title}</h1>
                        <ReactMarkdown source={article.content} />
                        <p className="uk-article-meta">
                          <Moment format="MMM Do YYYY">
                            {article.published_at}
                          </Moment>
                        </p>
                      </div>
                    </div>
                  </article>
                );
            }}
        </Query>
    )
}

export default Article
