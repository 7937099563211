import React from 'react'
import { useParams } from 'react-router'
import Articles from '../components/Articles'
import Query from '../components/Query'
import ReactMarkdown from 'react-markdown'
import CATEGORY_ARTICLES_QUERY from "../queries/category";

const Category = () => {
    let { id } = useParams()
    return (
        <Query query={CATEGORY_ARTICLES_QUERY} id={id}>
            {({ data: { category } }) => {
                return (
                  <div>
                    <div className="uk-section">
                      <div className="uk-container uk-container-large">
                        <h1>{category.name}</h1>
                        <ReactMarkdown source={category.content} />
                        <Articles articles={category.articles} />
                      </div>
                    </div>
                  </div>
                );
            }}
        </Query>
    )
}

export default Category