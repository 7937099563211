import React from 'react'
import Card from './Card'

const Articles = ({ articles }) => {
    return (
      <div>
        <div className="cat-grid">
          {articles.map(article => {
            return (
              <div key={article.id}>
                <Card article={article} key={`article_${article.id}`} />
              </div>
            );
          })}
        </div>
      </div>
    );
}

export default Articles