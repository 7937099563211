import React from 'react'
import { Link } from "react-router-dom"

const Card = ({ article }) => {
    const imageUrl = 
        article.image
          ? article.image.url
          : ''
    return (
      <div>
        <Link
          to={`/article/${article.id}`}
          className="cat-card uk-link-reset uk-card uk-card-body uk-background-cover uk-light uk-flex uk-flex-column uk-flex-wrap-between uk-height-medium uk-border-rounded uk-box-shadow-small uk-box-shadow-hover-large"
          data-src={imageUrl}
          data-srcset={imageUrl}
          data-uk-img
        >
          <div className="uk-height-1-1 uk-flex uk-flex-column uk-flex-between">
            <div>
              {article.category ? (
                <div className="uk-text-uppercase card-category">
                  {article.category.name}
                </div>
              ) : (
                ""
              )}
              <h4 className="uk-margin-remove-top uk-text-bold card-title">
                {article.title}
              </h4>
            </div>
            <div>
              <button className="uk-button uk-button-primary">Primary</button>
            </div>
          </div>
        </Link>
      </div>
    );
}

export default Card